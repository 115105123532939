<template>
  <div>
    <div class="flex flex-col py-7 items-center">
      <div class="flex w-full sm:w-[600px] lg:w-[800px] px-3 lg:px-0 justify-center rounded-2xl overflow-hidden">
        <div class="w-[242px] sm:w-[400px] lg:w-[600px]">
          <img src="../assets/necesidades-especiales/getacion.webp" alt="familia"
            class="h-64 w-full object-cover rounded-l-2xl" />
        </div>
        <div
          class="flex w-[130px] sm:w-[300px] lg:w-[400px] h-64 bg-gradient-to-t from-cyan-800 via-cyan-500 to-cyan-800 items-center justify-center rounded-r-2xl">
          <p class="text-[16px] sm:text-[18px] lg:text-[22px] font-bold text-white px-3 sm:px-0 text-center">
            Pasajera de Gestación
          </p>
        </div>
      </div>
    </div>
    <div>

    </div>
    <div class="flex justify-center mb-4">
      <a href="https://drive.google.com/file/d/1dZfn0WhK6ET0Yp0WsHE-IsldkUayG03U/view?usp=sharing" target="_blank"
        rel="noopener noreferrer"
        class="bg-cyan-800 hover:bg-cyan-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105">
        <div class="flex gap-3 items-center text-white">
          <img src="https://img.icons8.com/?size=100&id=13417&format=png&color=000000 " alt="ícono"
            class="w-8 h-8 md:w-8 md:h-8 lg:w-12 lg:h-12" />
          Descargar Formulario
        </div>
      </a>
    </div>
    <MoreInfo title="Información para Pasajera en Gestación" :items="informacionAdultosMayores" />
    <!-- Tabla de Recomendaciones -->
    <div class="flex justify-center pb-5">
      <div class=" mt-10 w-full lg:w-[800px] overflow-x-auto">
        <table class="table-auto border-collapse border border-gray-300 w-full">
          <thead>
            <tr class="bg-cyan-800 text-white">
              <th class="border border-gray-300 px-4 py-2">Semanas</th>
              <th class="border border-gray-300 px-4 py-2">Recomendaciones</th>
            </tr>
          </thead>
          <tbody>
            <tr class="odd:bg-gray-100 even:bg-white">
              <td class="border border-gray-300 px-4 py-2">0-28</td>
              <td class="border border-gray-300 px-4 py-2">
                Solo debe llenar y presentar el formulario de pasajera en gestación.
              </td>
            </tr>
            <tr class="odd:bg-gray-100 even:bg-white">
              <td class="border border-gray-300 px-4 py-2">29-33</td>
              <td class="border border-gray-300 px-4 py-2">
                Debe presentar un certificado médico legal, además de llenar y presentar el formulario de pasajera en
                gestación.
              </td>
            </tr>
            <tr class="odd:bg-gray-100 even:bg-white">
              <td class="border border-gray-300 px-4 py-2">34 y más semanas</td>
              <td class="border border-gray-300 px-4 py-2">
                No se acepta el transporte de pasajeras gestantes a partir de la semana 33, salvo extrema necesidad, con
                los siguientes requisitos:
                <ul class="list-disc list-inside mt-2">
                  <li>Debe estar acompañada por un médico que asume toda la responsabilidad.</li>
                  <li>Debe llenar y firmar el formulario de Pasajera en Gestación.</li>
                </ul>
              </td>
            </tr>
            <tr class="odd:bg-gray-100 even:bg-white">
              <td class="border border-gray-300 px-4 py-2">Post parto</td>
              <td class="border border-gray-300 px-4 py-2">
                Si su parto fue hace menos de siete días, no se aceptará su transporte, de acuerdo a las recomendaciones
                de médicos autorizados en la materia.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import MoreInfo from "./MoreInfo.vue";

export default {
  name: "PasajeraGestacion",
  components: {
    MoreInfo,
  },
  data() {
    return {
      informacionAdultosMayores: [
        {
          id: 1,
          title: "PERSONAS DE LA TERCERA EDAD",
          description: `
              <div class="space-y-4">
                <p class="text-text-justify">
                    No olvide informarse adecuadamente para que podamos transportarte sin contratiempos.
                    Para que tengas un viaje sin complicaciones, debes informar al agente de viajes o TAMep de tu estado de gestación.
                </p>
                Siguiendo las recomendaciones de la Superintendencia de Transportes y de la Asociación Internacional de Transporte Aéreo (IATA).
              </div>`,
          imageUrl: require("../assets/requi-viajar/imagen-familiaa.webp"),
        },
        {
          id: 2,
          title: "RECOMENDACIONES",
          description: `
              <div class="space-y-4">
                <ul class="list-disc list-inside space-y-2">
                
                  <li>Se transportará pasajeras en etapa de gestación sin el respaldo del certificado médico hasta el término de los 6 meses de embarazo siempre y cuando se presente un documento que indique el tiempo de gestación actual.</li>
                  <li>Las mujeres con 7 meses de embarazo hasta antes de empezar el octavo mes, deberán portar consigo un certificado médico legal, en donde conste el buen estado de salud para viajar por vía aérea emitido en un período no mayor a los 7 días para poder acceder al servicio de TAMep.</li>
                </ul>
              </div>`,
        },
      ],
    };
  },
};
</script>