<template>
    <div>
      <div class="flex flex-col py-7 items-center">
        <div
          class="flex w-full sm:w-[600px] px-3 lg:px-0 lg:w-[800px] overflow-hidden justify-center rounded-2xl"
        >
          <div class="w-[242px] sm:w-[400px] lg:w-[600px]">
            <img
              src="../assets/necesidades-especiales/viejos.webp"
              alt="familia"
              class="h-64 w-full object-cover rounded-l-2xl"
            />
          </div>
          <div
            class="flex w-[130px] sm:w-[300px] lg:w-[400px] h-64 bg-gradient-to-t from-cyan-800 via-cyan-500 to-cyan-800 items-center justify-center rounded-r-2xl"
          >
            <p
              class="text-[16px] sm:text-[18px] lg:text-[22px] font-bold text-white text-wrap px-3 sm:px-0 text-center"
            >
              Adultos Mayores
            </p>
          </div>
        </div>
      </div>
      <MoreInfo
        title="Información para Adultos Mayores"
        :items="informacionAdultosMayores"
      />
    </div>
  </template>
  
  <script>
  import MoreInfo from "./MoreInfo.vue";
  
  export default {
    name: "MenoresNOacom",
    components: {
      MoreInfo,
    },
    data() {
      return {
        informacionAdultosMayores: [
          {
            id: 1,
            title: "PERSONAS DE LA TERCERA EDAD",
            description: `
              <div class="space-y-4">
                <p class="text-text-justify">Los y las ciudadanos (as) bolivianos mayores de 60 años tienen preferencias en lo que se refiere al a la compra del pasaje,
                atención, asignación de asientos y embarque,según lo expuesto en la Ley 1886 de 01.10.98 de Derechos y Privilegios para los mayores y Seguro gratuito de vejez en Bolivia.
                Si usted o la persona que lo acompaña necesita un servicio especial, TAMep está a su servicio. </p>
              </div>`,
            imageUrl: require("../assets/necesidades-especiales/viejos.webp"),
          },
          {
            id: 2,
            title: "BENEFICIOS",
            description: `
              <div class="space-y-4">
                <ul class="list-disc list-inside space-y-2">
                  <li>Cuenta con el <span class="font-bold text-blue-900">40% de descuento</span> de la tarifa plena a sola presentación de su documento de identidad.</li>
                  <li>Atención diferenciada y privilegiada en oficinas y aeropuertos.</li>
                </ul>
              </div>`,
            imageUrl: require("../assets/requi-viajar/imagen-familiaa.webp"),
          },
        ],
      };
    },
  };
  </script>
  