<template>
  <div class="bg-gradient-to-br from-blue-900 to-blue-800 shadow-lg rounded-xl mx-3 sm:mx-8 px-8 pt-6 pb-10">
    <h2 class="text-xl sm:text-2xl font-bold mb-4 text-yellow-300 text-center">{{ title }}</h2>
    <div class="space-y-4">
      <div 
        v-for="item in items" 
        :key="item.id" 
        class="bg-gradient-to-r from-blue-100 to-blue-50 rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-xl hover:scale-[1.02]"
      >
        <button 
          class="w-full px-6 py-4 flex justify-between items-center bg-gradient-to-r from-blue-200 to-blue-100 hover:from-blue-300 hover:to-blue-200 transition-colors duration-300"
          @click="toggleItem(item)"
        >
          <span class="font-semibold text-lg text-blue-900">{{ item.title }}</span>
          <svg 
            class="h-6 w-6 text-blue-800 transition-transform duration-300"
            :class="{'rotate-180': item.isOpen}"
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </button>
        <div 
          class="overflow-hidden transition-all duration-300"
          :class="item.isOpen ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'"
        >
          <div class="p-6">
            <div class="flex flex-col md:flex-row gap-6">
              <img 
                v-if="item.imageUrl" 
                :src="item.imageUrl" 
                :alt="item.title"
                class="w-full md:w-1/3 rounded-lg object-cover h-48"
              >
              <div 
                class="text-blue-900 flex-1"
                v-html="item.description"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MoreInfo',
  props: {
    title: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true,
      validator: prop => {
        return prop.every(item => 
          typeof item.id !== 'undefined' &&
          typeof item.title === 'string' &&
          typeof item.description === 'string'
        )
      }
    }
  },
  data() {
    return {
      localItems: []
    }
  },
  created() {
    this.localItems = this.items.map(item => ({
      ...item,
      isOpen: false
    }))
  },
  methods: {
    toggleItem(item) {
      item.isOpen = !item.isOpen
    }
  }
}
</script>