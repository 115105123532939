<template>
    <div>
        <h1 class="text-white text-2xl">Equipaje</h1>
    </div>
</template>

<script>
export default {
    name: 'EquipajeMas'
}

</script>