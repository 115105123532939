<template>
  <!-- <div class="p-4 lg:mt-[-120px] mt-[-140px] mb-28">
    <div class="absolute bg-gray-900 top-0 left-0 right-0 h-[80px] z-10"></div>
    <div class="flex justify-between flex-col lg:flex-row">
      <div class="h-[570px] lg:w-[1400px] w-[345px] md:w-[800px] sm:w-[500px] ">
        <iframe src="https://tamep.transclicksolutions.com/check-in/search-booking" class="w-full h-[645px] scale-90">
          Tu navegador no soporta iframes.
        </iframe>
      </div>
      <div class="ml-4 lg:mt-20 mt-5 ">
        <div class="flex flex-col m-6 items-center mt-12">
          <h1 class="font-bold text-yellow-400 text-center text-2xl">EMPRESA PÚBLICA TRANSPORTE AÉREO MILITAR (EPTAM)
          </h1>
          <div class="lg:w-3/4 items-center w-full ">
            <p class="text-white text-justify">
              Estimado pasajero, por favor lea atentamente las siguientes condiciones de uso del servicio Web Check In y su contrato de transporte de pasajeros en www.tamep.bo . Es importante que verifique la hora de presentación en el aeropuerto y las condiciones para el transporte y facturación de su equipaje.
            </p>
          </div>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-6">
          <button @click="setTab('1')" :class="buttonClass('default')">Web Check-In</button>
          <button @click="setTab('2')" :class="buttonClass('esbozado')">Presentación en el Aeropuerto</button>
          <button @click="setTab('3')" :class="buttonClass('tonal')">Equipaje</button>
          <button @click="setTab('4')" :class="buttonClass('tonal')">Artículos Restringidos</button>
          <button @click="setTab('5')" :class="buttonClass('tonal')">Mercancías Peligrosas</button>
          <button @click="setTab('6')" :class="buttonClass('tonal')">Excepciones</button>
        </div>
        <div v-if="currentTab === '2'" class="mt-4">
          <div class="bg-gray-200 p-6 rounded-lg">
            <p class="text-gray-800 text-justify">
              El Web Check-In de la EPTAM está diseñado para hacer su proceso de embarque más rápido y conveniente. Sin
              embargo, existen algunas restricciones y consideraciones importantes que debe tener en cuenta:<br>
              <span class="font-bold">Disponibilidad y Requisitos</span> <br>
              • Periodo de Check-In: Disponible desde 48 horas hasta 2 horas antes de la salida del vuelo.<br>
              • Pasajeros Permitidos: Adultos (18 años o más).<br>• Límite por Reserva: Máximo 9 pasajeros.<br>•
              Información
              Necesaria: <br>o Código de Reserva (6 letras, ejemplo: "HW2MFB")<br>o Apellido del pasajero <br> <span
                class="font-bold">Restricciones del Web Check-In</span><br>El Web Check-In no está disponible para las
              siguientes categorías de pasajeros, quienes deberán realizar su check-in hasta hora y media antes de la
              salida
              del vuelo en los mostradores del aeropuerto:<br>a) Menores de 0 a 2 años: <br>o Deben presentarse con el
              adulto acompañante en los mostradores de la EPTAM.<br>o Se requieren autorizaciones según la normativa
              vigente.<br>b) Menores de 2 a 17 años: <br>o Si viajan con un solo padre o no acompañados, deben
              presentarse
              en los mostradores de la EPTAM <br>o Necesitan autorizaciones de la autoridad competente.<br>o Nota: Se
              consideran "menores no acompañados" a los pasajeros entre 5 y 17 años que viajan solos.<br>c) Pasajeros
              que
              requieren asistencia especial.<br>d) Pasajeros en Lista de Espera <br>
              <span class="font-bold"> Importante</span> <br>• Para garantizar un viaje sin contratiempos, asegúrese de
              cumplir con todos los requisitos y restricciones mencionados.<br>• Si tiene dudas sobre su elegibilidad
              para
              el Web Check-In, le recomendamos contactar a nuestro servicio al cliente o realizar el check-in en el
              aeropuerto.
            </p>
          </div>
        </div>
        <div v-if="currentTab === '1'" class="mt-4">
          <div class="bg-gray-200 p-6 rounded-lg ">
            <p class="text-gray-800 text-justify">
              Debe presentar el Pase a Bordo electrónico (digital o impreso) y el documento de identidad vigente.<br>
              <span class="font-bold">• Sin Equipaje para Despachar:</span>Presentarse hasta 1 hora antes de la salida
              del
              vuelo en la sala de preembarque. Verifique en las pantallas de aeropuerto la puerta asignada<br>
              <span class="font-bold">• Con Equipaje para Facturar:</span>Presentarse 1 hora antes de la salida en los
              mostradores de EPTAM
            </p>
          </div>
        </div>
        <div v-if="currentTab === '3'" class="mt-4">
          <div class="bg-gray-200 p-6 rounded-lg">
            <p class="text-gray-800 text-justify">
              <span class="font-bold"> Equipaje de Mano</span><br>• 1 pieza por pasajero.
              <br>• Peso máximo: 5 kg.<br>• Dimensiones máximas: 50 cm Alto x 35 cm largo x 20 cm de ancho.<br>
              <span class="font-bold"> Equipaje Facturado</span><br>• Adultos: Hasta 20 kg.<br>• Menores (2-12 años):
              Hasta 10 kg.<br>• Exceso de equipaje: Sujeto a espacio y pago adicional, hasta 30 kg. por pieza. El
              equipaje
              debe ser contenido en maletas o contenedores rígidos que aseguren su transporte, los objetos de valor y
              frágiles deben ser declarados. Otro tipo de contenedor será rechazado o se transportará bajo
              responsabilidad
              total del pasajero.
            </p>
          </div>
        </div>
        <div v-if="currentTab === '4'" class="mt-4">
          <div class="bg-gray-200 p-6 rounded-lg">
            <p class="text-gray-800 text-justify">
              <span class="font-bold"> En Equipaje de Mano</span><br>No se permiten:<br>• Elementos Contundentes,
              filosos, punzantes o cortantes.<br>• Encendedores y fósforos.<br> • Imitaciones de armas o juguetes
              bélicos.<br>• Artículos que puedan producir lesiones.<br>
              <span class="font-bold"> En Equipaje Facturado</span><br>No se recomienda transportar:<br>• Artículos
              frágiles<br>• Dinero, joyas, metales preciosos<br>• Dispositivos electrónicos (celulares, cámaras,
              etc.)<br>•
              Documentos de Identificación
            </p>
          </div>
        </div>
        <div v-if="currentTab === '5'" class="mt-4">
          <div class="bg-gray-200 p-6 rounded-lg">
            <p class="text-gray-800 text-justify">
              <span class="font-bold">Está prohibido transportar:</span><br>• Sustancias infecciosas<br>• Gases
              comprimidos<br>• Corrosivos<br>• Explosivos<br>• Líquidos o sólidos inflamables<br>• Materiales
              radioactivos<br>• Oxidantes o irritantes<br>• Tóxicos o venenosos<br>• Maletines de seguridad con baterías
              de
              litio o dispositivos pirotécnicos<br>• Dispositivos discapacitadores (macé, aerosoles picantes, etc.)
            </p>
          </div>
        </div>
        <div v-if="currentTab === '6'" class="mt-4">
          <div class="bg-gray-200 p-6 rounded-lg">
            <p class="text-gray-800 text-justify">
              <span class="font-bold"> Excepciones:</span><br>• Artículos medicinales o de tocador en pequeñas
              cantidades<br>• Un barómetro transportado por representante oficial (solo como equipaje de mano)<br>• Un
              termómetro clínico pequeño para uso personal<br>Para más información o dudas, por favor contacte a nuestro
              servicio de atención al cliente.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="flex flex-col justify-center items-center">
  <h1 class="text-center text-4xl font-bold py-3 text-yellow-400">Comunicado al Público en General</h1>
  <div class="w-5/6 backdrop:blur-md bg-white bg-opacity-10 rounded-xl p-3 sm:p-6 mx-8 my-2">
    <p class="text-gray-300 text-xl sm:text-3xl text-center font-semibold">
      HASTA NUEVO AVISO, EL CHECK-IN SE REALIZARÁ EN LOS MOSTRADORES DEL AEROPUERTO
    </p>
  </div>
</div>


</template>

<script>
export default {
  name: 'WebChe',
  data() {
    return {
      currentTab: 'default',
    };
  },
  methods: {
    setTab(tab) {
      this.currentTab = tab;
    },
    buttonClass(tab) {
      return `font-bold py-3 text-black px-4 border rounded ${this.currentTab === tab ? 'bg-yellow-300' : 'bg-blue-500'} hover:bg-yellow-300`;
    }
    ,
  },
};
</script>
